<template>
  <div class="adverts-filter flex-wrap flex-center ">
    <ul
      class="list-unstyled list-inline"
      :aria-label="t('filter_profile_ads.active_finished_select')"
    >
      <li :class="liClass('all')">
        <a @click="setFilterTy('all')">
          {{ t("filter_profile_ads.all_ads") }}
        </a>
      </li>
      <li :class="liClass('active')">
        <a @click="setFilterTy('active')">
          {{ t("filter_profile_ads.active_ads") }}
        </a>
      </li>
      <li :class="liClass('finished')">
        <a @click="setFilterTy('finished')">
          {{ t("filter_profile_ads.finished_ads") }}
        </a>
      </li>
    </ul>

    <!-- TODO: sort by moderation status -->
    <!-- <form class="key-submit-form">
      <div class="styled-select inline-select change-submit">
        <select name="type">
          <option value=""> {{ t("profile_ads.sort.all") }} </option>
          <option value="1">
            {{ t("profile_ads.sort.pending") }}
          </option>
          <option value="2">
            {{ t("profile_ads.sort.active") }}
          </option>
          <option value="3">
            {{ t("profile_ads.sort.inactive") }}
          </option>
          <option value="4">
            {{ t("profile_ads.sort.banned") }}
          </option>
        </select>
      </div>
    </form> -->
  </div>
</template>

<script>
import { ref } from "vue";

import { useI18n } from "vue-i18n";

export default {
  name: "FilterProfileAds",
  props: {
    filter: {
      type: String,
      required: false,
      default: "all",
    },
  },
  emits: [
    /**
     * Изменилось значение фильтра.
     * Параметры:
     *  1. Новое значение
     *     Одно из:
     *      - 'all'
     *      - 'active'
     *      - 'finished'
     */
    "filter-changed",
  ],
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      filterType: ref(String(props.filter)),
    };
  },
  methods: {
    setFilterTy(ty) {
      this.filterType = ty;
      this.$emit("filter-changed", ty);
    },
    liClass(ty) {
      if (ty == this.filterType) {
        return "active";
      }

      return null;
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}

.adverts-filter ul li.active a {
  color: #1c83fc;
  border-color: #1c83fc;
}
</style>
