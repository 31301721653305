<template>
  <div class="profile-advert-listing">
    <ul>
      <li :key="ad.uuid" v-for="(ad, index) in ads">
        <AdProfile
          :name="ad.name"
          :status="getState(ad.state)"
          :price="ad.price"
          :uuid="ad.uuid"
          :photo="ad.mainPhoto"
          :date="ad.date"
          @delete-ad-clicked="(uuid) => $emit('delete-ad', uuid)"
          @activate-ad-clicked="(uuid) => $emit('activate-ad', uuid)"
          :ref="(el) => setLastAdRef(el, index)"
        />
      </li>
    </ul>

    <button v-if="showMoreButton" class="af-button" @click="showMoreClicked">
      {{ moreButtonTitle ? moreButtonTitle : t("list_ads_profile.more_ads") }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import AdProfile from "../components/AdProfile.vue";

import { AdState } from "api/ad_details.js";

export default {
  name: "ListAdsProfile",
  components: {
    AdProfile,
  },
  props: {
    /** Список объявлений, которые нужно отобразить */
    ads: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    /** Текст кнопки "Показать еще" */
    moreButtonTitle: {
      type: String,
      required: false,
      default: null,
    },
    /** Нужно ли показывать кнопку "Показать еще" */
    showMoreButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    /**
     * Удалить объявление
     * Параметры:
     *   1. uuid
     */
    "delete-ad",
    /**
     * Активировать объявление
     * Параметры:
     *  1. uuid
     */
    "activate-ad",

    "load-more",
  ],
  setup() {
    const { t } = useI18n();

    return {
      t,
      lastAdRef: ref(null),
    };
  },
  methods: {
    getState(state) {
      switch (AdState[state]) {
        case AdState.ACTIVE: {
          return "active";
        }
        case AdState.SOLD:
        case AdState.DELETED:
        case AdState.FINALIZED: {
          return "sold";
        }
        case AdState.PENDING:
        case AdState.WAITING: {
          return "pending";
        }
        case AdState.DECLINED: {
          return "banned";
        }
        default: {
          return "unknown";
        }
      }
    },
    setLastAdRef(el, index) {
      if (index != this.ads.length - 1) {
        return;
      }

      if (el) {
        this.lastAdRef = el;
      }
    },
    showMoreClicked() {
      var last = this.lastAdRef;
      if (Boolean(last) && Boolean(last.lastBtn)) {
        last.lastBtn.focus();
      }
      this.$emit("load-more");
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
