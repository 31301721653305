<template>
  <div class="author-panel">
    <div class="white-block white-block-extra-padding">
      <div class="white-block-title">
        <h1>{{ t("profile_ads.your_ads") }}</h1>
      </div>

      <div class="white-block-content">
        <FilterProfileAds
          :filter="filter"
          @filter-changed="(f) => (filter = f)"
        />

        <div>
          <div
            v-if="
              (Boolean(activeAds) && activeAds.length > 0) ||
                (Boolean(finishedAds) && finishedAds.length > 0)
            "
          >
            <div class="profile-advert-listing-titles profile-advert">
              <div></div>
              <div>{{ t("list_ads_profile.title") }}</div>
              <div>{{ t("list_ads_profile.status") }}</div>
              <div>{{ t("list_ads_profile.price") }}</div>
              <div>{{ t("list_ads_profile.published") }}</div>
              <div class="profile-advert-views">
                {{ t("list_ads_profile.views") }}
              </div>
              <div class="action">{{ t("list_ads_profile.action") }}</div>
            </div>

            <h2 class="sr-only">{{ t("profile_ads.active_ads_title") }}</h2>
            <ListAdsProfile
              v-if="['active', 'all'].includes(filter)"
              :ads="activeAds"
              :moreButtonTitle="t('profile_ads.more_active')"
              :showMoreButton="Boolean(activeAdsCursor)"
              @delete-ad="deleteAd"
              @activate-ad="activateAd"
              @load-more="loadMoreActive"
            />

            <h2 class="sr-only">{{ t("profile_ads.finished_ads_title") }}</h2>
            <ListAdsProfile
              v-if="['finished', 'all'].includes(filter)"
              :ads="finishedAds"
              :moreButtonTitle="t('profile_ads.more_finished')"
              :showMoreButton="Boolean(finishedAdsCursor)"
              @delete-ad="deleteAd"
              @activate-ad="activateAd"
              @load-more="loadMoreFinished"
            />
          </div>
          <div v-else>
            <p>{{ t("profile_ads.no_ads") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";

import { getUserAds as getUserAdsApi } from "api/ad_details.js";
import {
  deleteAd as deleteAdApi,
  activateAd as activateAdApi,
} from "api/profile/ads.js";

import FilterProfileAds from "../components/FilterProfileAds.vue";
import ListAdsProfile from "../components/ListAdsProfile.vue";

export default {
  name: "ProfileAds",
  components: {
    FilterProfileAds,
    ListAdsProfile,
  },
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t("profile_ads.title"),
    });

    const store = useStore();

    const activeAds = ref([]);
    const activeAdsCursor = ref("");
    const finishedAds = ref([]);
    const finishedAdsCursor = ref("");

    const loadMoreActive = async () => {
      var cursor = activeAdsCursor.value;
      activeAdsCursor.value = "";

      var active = await getUserAdsApi({
        user_id: store.state.user.userId,
        ad_type: "active",
        cursor,
        size: 10,
      });

      activeAds.value.push(...active.ads);
      activeAdsCursor.value = active.cursor;
    };

    const loadMoreFinished = async () => {
      var cursor = finishedAdsCursor.value;
      finishedAdsCursor.value = "";

      var finished = await getUserAdsApi({
        user_id: store.state.user.userId,
        ad_type: "sold",
        cursor,
        size: 10,
      });

      finishedAds.value.push(...finished.ads);
      finishedAdsCursor.value = finished.cursor;
    };

    const loadData = async () => {
      await loadMoreActive();
      await loadMoreFinished();
    };

    return {
      t,
      activeAds,
      activeAdsCursor,
      finishedAds,
      finishedAdsCursor,
      filter: ref("all"),
      loadMoreActive,
      loadMoreFinished,
      loadData,
    };
  },
  methods: {
    async deleteAd(uuid) {
      var adIdx = this.activeAds.findIndex((ad) => ad.uuid == uuid);
      var ad = this.activeAds.find((ad) => ad.uuid == uuid);
      if (adIdx == -1) {
        console.error(
          "Ad with uuid =",
          uuid,
          "not found and cannot be deleted"
        );
        return;
      }

      await deleteAdApi(uuid);
      this.activeAds.splice(adIdx, 1);
      ad.state = "FINALIZED";
      this.finishedAds.push(ad);
    },
    async activateAd(uuid) {
      var adIdx = this.finishedAds.findIndex((ad) => ad.uuid == uuid);
      var ad = this.finishedAds.find((ad) => ad.uuid == uuid);
      if (adIdx == -1) {
        console.error(
          "Ad with uuid =",
          uuid,
          "not found and cannot be activated"
        );
        return;
      }

      await activateAdApi(uuid);
      this.finishedAds.splice(adIdx, 1);
      ad.state = "ACTIVE";
      this.activeAds.push(ad);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 18px;
}
</style>
