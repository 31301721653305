<template>
  <div
    class="profile-advert advert-112"
    data-bumpup=""
    data-highlight=""
    data-topad=""
    data-urgent=""
    data-homemap=""
  >
    <div :aria-hidden="true" tabindex="-1">
      <router-link
        :to="{ name: 'AdDetails', params: { id: uuid } }"
        tabindex="-1"
      >
        <div
          tabindex="-1"
          :style="thumbnailStyle(photo)"
          class="attachment-thumbnail size-thumbnail wp-post-image"
          aria-hidden="true"
        />
      </router-link>
    </div>
    <div>
      <div class="ad-name">
        <router-link :to="{ name: 'AdDetails', params: { id: uuid } }">
          {{ name }}
        </router-link>
      </div>
      <!-- TODO: показывать название категории -->
      <!-- <div class="profile-advert-cats">
        <a> Electronics </a>
        &gt;
        <a> Notebooks </a>
      </div> -->
    </div>
    <div>
      <div :class="statusClass" :aria-label="t('ad_profile.status.text')">
        {{ statusText.toUpperCase() }}
      </div>
    </div>
    <div>
      <div class="price">
        <span class="text-price" :aria-label="t('ad_profile.price')">
          {{ Number(price).toLocaleString() + " " + t("$") }}
        </span>
      </div>
    </div>
    <div
      class="profile-advert-expire"
      :aria-label="t('ad_profile.date_published')"
    >
      {{ dateString }}
    </div>
    <div class="profile-advert-views" :aria-label="t('ad_profile.view_count')">
      {{ totalViews ? totalViews : "" }}
      <i
        v-if="!totalViews"
        class="fas fa-question"
        :aria-label="t('ad_profile.unknown_views')"
        role="figure"
      ></i>
    </div>
    <div class="action">
      <router-link
        :to="{ name: 'AdDetails', params: { id: uuid } }"
        custom
        v-slot="{ navigate }"
      >
        <button v-bind="$attrs" :title="t('ad_profile.view')" @click="navigate">
          <slot />
          <i class="aficon-eye" aria-hidden="true"></i>
          <span class="icon-caption animation" aria-hidden="true">
            {{ t("ad_profile.view") }}
          </span>
        </button>
      </router-link>
      <!-- <a
        href="http://127.0.0.1:8080/author/admin/?screen=edit&amp;id=112"
        title="Edit Ad"
      >
        <i class="aficon-edit"></i>
        <span class="icon-caption animation">Edit</span>
      </a> -->
      <button
        v-if="statusChangeBtn"
        class="profile-delete-advert"
        :title="statusChangeBtn.caption"
        ref="lastBtn"
        @click="statusChangeClicked"
      >
        <i :class="statusChangeBtn.icon" aria-hidden="true"></i>
        <span class="icon-caption animation" aria-hidden="true">
          {{ statusChangeBtn.caption }}
        </span>
      </button>
      <!-- TODO: поднять объявление -->
      <!-- 
       <a
         class="profile-promote-advert"
         data-id="112"
         title="Promote Ad"
       >
         <i class="aficon-bullhorn"></i>
         <span class="icon-caption animation">Promote</span>
       </a> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import EmptyImg from "assets/img/empty-image.svg";
import ThumbnailStyle from "mixins/ThumbnailStyle.js";

import { getAdViews as getAdViewsApi } from "api/profile/ads.js";

export default {
  name: "AdProfile",
  props: {
    uuid: {
      type: String,
      required: false,
    },
    /** Название объявления */
    name: {
      type: String,
      required: true,
    },
    /**
     * @param {('pending'|'banned'|'active'|'sold'|'unknown')} status - Статус объявления
     */
    status: {
      type: String,
      validator(value) {
        return ["pending", "banned", "active", "sold", "unknown"].includes(
          value
        );
      },
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    date: {
      type: Date,
      required: true,
      default() {
        return new Date();
      },
    },
    photo: {
      type: Object,
      required: false,
      default() {
        return {
          thumb: EmptyImg,
          small: EmptyImg,
          big: EmptyImg,
        };
      },
    },
  },
  emits: [
    /**
     * Была нажата кнопка удалить объявление
     * Параметры:
     *   1. uuid объявления, которое нужно удалить
     */
    "delete-ad-clicked",
    /**
     * Была нажата кнопка активировать объявление
     * Параметры:
     *   1. uuid объявления, которое нужно активировать
     */
    "activate-ad-clicked",
  ],
  mixins: [ThumbnailStyle],
  setup() {
    const { t } = useI18n();

    return {
      t,
      totalViews: ref(null),
      /** Ref на ссылку с названием объявления. Используется в родителе */
      lastBtn: ref(null),
    };
  },
  async mounted() {
    var views = await getAdViewsApi({ ad_id: this.uuid, filter: "total" });
    this.totalViews = views.totalViews;
  },
  computed: {
    statusClass() {
      switch (this.status) {
        case "active": {
          return "status live";
        }
        case "pending": {
          return "status pending";
        }
        case "sold":
        case "banned": {
          return "status expired";
        }
        default: {
          return "status unknown";
        }
      }
    },
    statusText() {
      switch (this.status) {
        case "active": {
          return this.t("ad_profile.status.live");
        }
        case "pending": {
          return this.t("ad_profile.status.pending");
        }
        case "sold": {
          return this.t("ad_profile.status.sold");
        }
        case "banned": {
          return this.t("ad_profile.status.banned");
        }
        default: {
          return this.t("ad_profile.status.unknown");
        }
      }
    },
    statusChangeBtn() {
      switch (this.status) {
        case "active": {
          return {
            icon: "aficon-trash-alt",
            caption: this.t("ad_profile.delete"),
          };
        }
        case "sold":
        case "banned": {
          return {
            icon: "aficon-redo",
            caption: this.t("ad_profile.activate"),
          };
        }
        case "pending":
        default: {
          return null;
        }
      }
    },
    dateString() {
      return this.date.toLocaleDateString({
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
  methods: {
    statusChangeClicked() {
      switch (this.status) {
        case "active": {
          var deleteText = this.t("ad_profile.confirm_ad_delete", {
            name: this.name,
          });
          if (confirm(deleteText)) {
            this.$emit("delete-ad-clicked", this.uuid);
          }
          return;
        }
        case "sold":
        case "banned": {
          var activateText = this.t("ad_profile.confirm_ad_activate", {
            name: this.name,
          });
          if (confirm(activateText)) {
            this.$emit("activate-ad-clicked", this.uuid);
          }
          return;
        }
        case "pending":
        default: {
          console.error("Tried to toggle pending ad:", this.uuid);
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.status.live {
  background: #1c83fc;
}

.status.unknown {
  background: #000;
}

.profile-advert .action button:nth-child(1):hover i {
  background: #1c83fc;
  color: #fff;
}

.size-thumbnail {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center center;
}

.ad-name {
  font-size: 14px;
}

.profile-delete-advert {
  display: inline-block;
}
</style>
