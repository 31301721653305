import { grpc } from "@improbable-eng/grpc-web";

/** API для экрана просмотра объявлений пользователя */

import { StatRequest, SetStateRequest } from "../proto/photos_pb.js";

import { ApiService } from "../proto/photos_pb_service.js";
import { getMeta } from "../utils/meta.js";
import { AdState } from "../ad_details.js";

/**
 * Получить количество просмотров объявления
 * @param {String} {ad_id - uuid объявления
 * @param {String} filter} - фильтр просмотров объявления
 * @returns {Object} Вернет объект с полем totalViews
 */
export function getAdViews({ ad_id, filter }) {
  let request = new StatRequest();

  switch (filter) {
    case "total": {
      request.setFilter("total");
      break;
    }
    case "today": {
      request.setFilter("today");
      break;
    }
    default: {
      throw new "Invalid views filter: "() + filter;
    }
  }
  request.setAdId(ad_id);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.GetAdStat, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.GetAdStat",
            status,
            statusMessage,
            message,
          });
        }

        var obj = message.toObject();

        return resolve({
          totalViews: obj.totalViews,
        });
      },
    });
  });
}

/**
 * Деактивировать объявление с ad_id
 * @param {String} ad_id - id объявления, которое нужно деактивировать
 */
export function deleteAd(ad_id) {
  let request = new SetStateRequest();
  request.setAdId(ad_id);
  request.setState(AdState.FINALIZED);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.SetAdState, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.SetAdState",
            status,
            statusMessage,
            message,
          });
        }

        return resolve();
      },
    });
  });
}

/**
 * Активировать объявление с ad_id
 * @param {String} ad_id - id объявления, которое нужно активировать
 */
export function activateAd(ad_id) {
  let request = new SetStateRequest();
  request.setAdId(ad_id);
  request.setState(AdState.ACTIVE);

  return new Promise((resolve, reject) => {
    grpc.unary(ApiService.SetAdState, {
      host: process.env.VUE_APP_API_URL,
      metadata: getMeta(),
      request,
      onEnd: ({ status, statusMessage, message }) => {
        if (status != grpc.Code.OK) {
          return reject({
            name: "error ApiService.SetAdState",
            status,
            statusMessage,
            message,
          });
        }
        return resolve();
      },
    });
  });
}
